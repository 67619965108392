import { createRouter,createWebHistory } from "vue-router";
const routes = [
    {
        path:'/',
        meta:{title:'2024 中国SaaS大会'},
        component: () => import('../view/saas_2024/index_2024.vue'),
    },
    {
        path:'/2023',
        meta:{title:'2023 中国SaaS大会'},
        component: () => import('../view/saas_2023/index_2023.vue'),
    },
]
const router = createRouter({
    history: createWebHistory(),
    routes,
    // scrollBehavior(to, from, savedPosition) {
    //     return { top: 0 };
    // },
})
router.beforeEach((to,from,next)=>{
    if(to.meta && to.meta.title){
        document.title = to.meta.title
    }
    next()
})
export default router